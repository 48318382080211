export default (i18n) => {
  const defaultData = (required) => ({
    value: '',
    required,
  });

  const contactFields = [
    { name: 'telprospect', field: 'phone', type: 'tel' },
    { name: 'emailprospect', field: 'email', type: 'email' },
  ];

  const additionnalFields = [
    {
      name: 'champaprospect',
      field: 'contract',
    },
    {
      name: 'champbprospect',
      field: 'role',
    },
    {
      name: 'champfprospect',
      field: 'interest',
    },
  ];

  return [
    {
      ...defaultData(true),
      name: 'societeprospect',
      validator: 'text',
      field: {
        id: 'company',
        label: i18n.t('gamer.entries.company.label'),
      },
      messages: i18n.tm('gamer.entries.company.messages'),
    },
    {
      ...defaultData(false),
      name: 'effectifprospect',
      field: {
        id: 'staff',
        label: i18n.t('gamer.entries.staff.label'),
        type: 'number',
      },
      messages: i18n.tm('gamer.entries.staff.messages'),
    },
    {
      ...defaultData(true),
      name: 'civiliteprospect',
      field: {
        id: 'civility',
        label: i18n.t('gamer.entries.civility.label'),
        type: 'select',
        options: i18n.tm('gamer.entries.civility.options'),
      },
      messages: i18n.tm('gamer.entries.civility.messages'),
    },
    {
      ...defaultData(true),
      name: 'prenomprospect',
      validator: 'text',
      field: {
        id: 'firstname',
        label: i18n.t('forms.entries.firstname.label'),
      },
      messages: i18n.tm('forms.entries.firstname.messages'),
    },
    {
      ...defaultData(true),
      name: 'nomprospect',
      validator: 'text',
      field: {
        id: 'name',
        label: i18n.t('forms.entries.name.label'),
      },
      messages: i18n.tm('forms.entries.name.messages'),
    },
    {
      ...defaultData(false),
      name: 'adresseprospect',
      field: {
        id: 'address',
        label: i18n.t('forms.entries.address.label'),
        type: 'address',
      },
      messages: i18n.tm('forms.entries.address.messages'),
    },
    {
      ...defaultData(false),
      name: 'cpprospect',
      validator: 'zipcode',
      field: {
        id: 'zipcode',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.tm('forms.entries.zipcode.messages'),
    },
    {
      ...defaultData(false),
      name: 'villeprospect',
      validator: 'text',
      field: {
        id: 'city',
        label: i18n.t('forms.entries.city.label'),
      },
      messages: i18n.tm('forms.entries.city.messages'),
    },
    ...contactFields.map(({ name, field, type }) => ({
      ...defaultData(false),
      name,
      validator: field,
      field: {
        id: field,
        label: i18n.t(`forms.entries.${field}.label`),
        type,
      },
      messages: i18n.tm(`forms.entries.${field}.messages`),
    })),
    {
      ...defaultData(false),
      name: 'commentprospect',
      validator: 'textarea',
      field: {
        id: 'comment',
        label: i18n.t('forms.entries.message.label'),
        type: 'textarea',
      },
      messages: i18n.tm('forms.entries.message.messages'),
    },
    ...additionnalFields.map(({ name, field }) => ({
      ...defaultData(false),
      name,
      field: {
        id: field,
        label: i18n.t(`gamer.entries.${field}.label`),
        type: 'select',
        options: i18n.tm(`gamer.entries.${field}.options`),
      },
      messages: i18n.tm(`gamer.entries.${field}.messages`),
    })),
  ];
};
