import { richText, stripRichTextWrapperTag } from '../../utils/html';
import api from './api';
import {
  routerLinks,
  productLinks,
  marketLinks,
} from '../../utils/fetch-links';
import mergeI18n from '~/utils/merge-i18n';
import gamerI18n from '~/i18n/messages/fr-fr.gamer.json';
import formsI18n from '~/i18n/messages/fr-fr.forms.json';
import azzureI18n from '~/i18n/messages/fr-fr.azzure.json';
import pipedriveI18n from '~/i18n/messages/fr-fr.pipedrive.json';
import { getSteps } from './api/marketplace/steps';

async function adapter(
  { id },
  data,
  { $i18n, $prismic, $enhancedLinkSerializer }
) {
  if (!id) {
    return null;
  }

  const { data: formData } = await $prismic.client.getByID(id, {
    fetchLinks: [...routerLinks, ...productLinks, ...marketLinks],
  });

  if (!formData) return null;

  const inputs = [];

  mergeI18n(
    $i18n,
    formData.form_api === 'gamer'
      ? Object.assign({}, gamerI18n, formsI18n)
      : formsI18n
  );

  const apiData = api(formData, $i18n, 'form_push');

  if (formData.form_api === 'azzur') {
    Object.keys(formsI18n).forEach((key) => {
      if (key in azzureI18n) {
        formsI18n[key] = azzureI18n[key];
      }
    });
    apiData.form[0].field.options = azzureI18n.forms.entries.civility.options;
  }

  // this is only useful when it's multi-step form
  if (formData.form_api === 'pipedrive') {
    Object.keys(formsI18n).forEach((key) => {
      if (key in pipedriveI18n) {
        formsI18n[key] = pipedriveI18n[key];
      }
    });
    apiData.form[0].field.options =
      pipedriveI18n.forms.entries.civility.options;
  }

  const withSteps = formData.with_steps;

  if (apiData.form) {
    inputs.push(...apiData.form);
  }

  const actions = formData.form_actions || [];
  const buttons = actions.map((button) => {
    const link = $enhancedLinkSerializer(button.cta_link);

    let cta = {};
    if (link) {
      cta = {
        ...link,
      };
      cta.variant = 'secondary';
    } else {
      cta.type = 'submit';
    }
    return {
      label: button.cta_label,
      cta,
    };
  });

  if (withSteps) {
    const steps = getSteps(apiData.form);
    if (!steps) return;
    return {
      type: formData.form_api,
      urlType: data.type,
      emailSubject: formData.mailto[0]?.mailto_custom_subject,
      withSteps,
      form: {
        labelStep: formsI18n.labelStep,
        title: stripRichTextWrapperTag(formData.form_title),
        intro: richText(formData.form_intro),
        steps,
        mention: richText(formData.form_mention),
        buttons,
        hiddenFields: apiData.hiddenFields,
      },
    };
  } else {
    return {
      type: formData.form_api,
      urlType: data?.type,
      emailSubject: formData.mailto[0]?.mailto_custom_subject,
      form: {
        title: stripRichTextWrapperTag(formData.form_title),
        intro: richText(formData.form_intro),
        inputs,
        mention: richText(formData.form_mention),
        buttons,
      },
    };
  }
}

export default adapter;
