export default (i18n) => {
  const defaultData = {
    value: '',
    required: true,
  };

  return [
    {
      ...defaultData,
      name: 'civility',
      field: {
        id: 'civility',
        label: i18n.t('forms.entries.civility.label'),
        type: 'select',
        options: [
          {
            value: '',
            label: 'Civilité',
          },
          {
            value: 'M.',
            label: 'Monsieur',
          },
          {
            value: 'Mme',
            label: 'Madame',
          },
          {
            value: 'Mlle',
            label: 'Mademoiselle',
          },
        ],
      },
      messages: i18n.tm('forms.entries.civility.messages'),
    },
    {
      ...defaultData,
      name: 'surname',
      validator: 'text',
      field: {
        id: 'name',
        label: i18n.t('forms.entries.name.label'),
      },
      messages: i18n.tm('forms.entries.name.messages'),
    },
    {
      ...defaultData,
      name: 'name',
      validator: 'text',
      field: {
        id: 'firstname',
        label: i18n.t('forms.entries.firstname.label'),
      },
      messages: i18n.tm('forms.entries.firstname.messages'),
    },
    {
      ...defaultData,
      name: 'birthDate',
      validator: 'age',
      field: {
        id: 'birthday',
        label: i18n.t('forms.entries.birthday.label'),
        placeholder: i18n.t('forms.entries.birthday.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
      },
      messages: i18n.tm('forms.entries.birthday.messages'),
    },
    {
      ...defaultData,
      name: 'zipCode',
      validator: 'zipcode',
      field: {
        id: 'address',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.tm('forms.entries.zipcode.messages'),
    },
    {
      ...defaultData,
      name: 'phone',
      validator: 'mobile',
      field: {
        id: 'phone',
        label: i18n.t('forms.entries.mobile.label'),
        type: 'tel',
      },
      messages: i18n.tm('forms.entries.mobile.messages'),
    },
    {
      ...defaultData,
      name: 'email',
      validator: 'email',
      field: {
        id: 'email',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.tm('forms.entries.email.messages'),
    },
  ];
};
