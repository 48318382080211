const DEFAULT_PIPEDRIVE_TYPE = 'rcd rich';

const getRCDRichFields = (i18n, formType) => [
  ...getRCDSimpleFields(i18n, formType),
  {
    name: 'rcd_qualipv_certification',
    value: '',
    required: true,
    field: {
      id: 'rcd_qualipv_certification',
      type: 'radio',
      label: i18n.t('pipedrive.entries.rcdqualipvcertification.label'),
      options: [
        {
          value: '84',
          label: 'Oui',
        },
        {
          value: '85',
          label: 'Non',
        },
      ],
    },
    messages: i18n.tm('pipedrive.entries.rcdqualipvcertification.messages'),
  },
  {
    name: 'rcd_a_experience',
    value: '',
    required: true,
    field: {
      id: 'rcd_a_experience',
      type: 'radio',
      label: i18n.t('pipedrive.entries.rcdaexperience.label'),
      options: [
        {
          value: '82',
          label: 'Oui',
        },
        {
          value: '83',
          label: 'Non',
        },
      ],
    },
    messages: i18n.tm('pipedrive.entries.rcdaexperience.messages'),
  },
  {
    name: 'rcd_dannee_experience',
    value: '',
    required: true,
    field: {
      id: 'rcd_dannee_experience',
      label: i18n.t('pipedrive.entries.rcddanneexperience.label'),
      mask: 'money',
      helper: i18n.t('pipedrive.entries.rcddanneexperience.helper'),
    },
    messages: i18n.tm('pipedrive.entries.rcddanneexperience.messages'),
  },
];

const getRCDSimpleFields = (i18n, formType) => {
  const specialFieldsFormSteps = [
    {
      name: 'rcd_activites_exercees',
      value: '',
      validator: 'text',
      required: true,
      field: {
        id: 'rcd_activites_exercees',
        label: i18n.t('pipedrive.entries.activitesexercees.label'),
        helper: i18n.t('pipedrive.entries.activitesexercees.helper'),
      },
      messages: i18n.tm('pipedrive.entries.activitesexercees.messages'),
    },
  ];

  const RCD_FIELDS = [
    ...(formType === 'form_steps' ? specialFieldsFormSteps : []),
    {
      name: 'rcd_activite_principale',
      value: '',
      required: true,
      field: {
        id: 'rcd_activite_principale',
        label: i18n.t('pipedrive.entries.activiteprincipale.label'),
        type: 'select',
        options: [
          {
            value: '695',
            label: 'Électricité',
          },
          {
            value: '703',
            label: 'Installations thermiques',
          },
          {
            value: '696',
            label: 'Plomberie',
          },
          {
            value: '707',
            label: 'Couverture',
          },
          {
            value: '709',
            label: 'Charpente',
          },
          {
            value: '698',
            label: 'Plâtrerie',
          },
          {
            value: '699',
            label: 'Peinture',
          },
          {
            value: '704',
            label: 'Revêtement dur / souple',
          },
          {
            value: '705',
            label: 'Menuiseries extérieures / intérieures',
          },
          {
            value: '710',
            label: 'Cuisines',
          },
          {
            value: '702',
            label: 'Maçonnerie',
          },
          {
            value: '697',
            label: 'Terrassement VRD',
          },
          {
            value: '700',
            label: 'Photovoltaïque',
          },
          {
            value: '706',
            label: 'Isolation',
          },
          {
            value: '711',
            label: 'Serrurerie',
          },
          {
            value: '712',
            label: 'Paysagiste',
          },
          {
            value: '713',
            label: 'Autre activité liée au BTP',
          },
        ],
      },
      messages: i18n.tm('pipedrive.entries.activiteprincipale.messages'),
    },
    {
      name: 'rcd_chiffre_affaires',
      value: '',
      required: true,
      field: {
        id: 'rcd_chiffre_affaires',
        label: i18n.t('pipedrive.entries.chiffreaffaires.label'),
        inputmode: 'numeric',
        mask: 'money',
        unit: '€',
        helper: i18n.t('pipedrive.entries.chiffreaffaires.helper'),
      },
      messages: i18n.tm('pipedrive.entries.chiffreaffaires.messages'),
    },
    {
      name: 'rc_decennale',
      value: '',
      required: true,
      field: {
        id: 'rc_decennale',
        type: 'radio',
        label: i18n.t('pipedrive.entries.rcdeccenale.label'),
        options: [
          {
            value: '80',
            label: 'Oui',
          },
          {
            value: '81',
            label: 'Non',
          },
        ],
      },
      messages: i18n.tm('pipedrive.entries.rcdeccenale.messages'),
    },
    {
      name: 'rcd_date_creation',
      value: '',
      validator: 'dateCreation',
      required: true,
      field: {
        id: 'rcd_date_creation',
        label: i18n.t('pipedrive.entries.datecreation.label'),
        placeholder: i18n.t('pipedrive.entries.datecreation.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
      },
      messages: i18n.tm('pipedrive.entries.datecreation.messages'),
    },
    {
      name: 'rcd_code_postal',
      value: '',
      required: true,
      validator: 'zipcode',
      field: {
        id: 'rcd_code_postal',
        label: i18n.t('pipedrive.entries.codepostal.label'),
      },
      messages: i18n.tm('pipedrive.entries.codepostal.messages'),
    },
  ];
  return RCD_FIELDS;
};

const getDOFields = (i18n) => {
  const DO_FIELDS = [
    {
      name: 'pipedrive_futur_projet',
      value: '',
      required: true,
      field: {
        id: 'pipedrive_futur_projet',
        label: i18n.t('pipedrive.entries.futurprojet.label'),
        type: 'select',
        options: [
          {
            value: '20',
            label: 'Construction d’une maison individuelle',
          },
          {
            value: '21',
            label: 'Rénovation d’une maison individuelle ou d’un appartement',
          },
        ],
      },
      messages: i18n.tm('pipedrive.entries.futurprojet.messages'),
    },
    {
      name: 'pipedrive_a_maitre',
      value: '',
      required: true,
      field: {
        id: 'pipedrive_a_maitre',
        type: 'radio',
        label: i18n.t('pipedrive.entries.amaitre.label'),
        options: [
          {
            value: '18',
            label: 'Oui',
          },
          {
            value: '19',
            label: 'Non',
          },
        ],
      },
      messages: i18n.tm('pipedrive.entries.amaitre.messages'),
    },
    {
      name: 'pipedrive_cout_estime',
      value: '',
      required: true,
      field: {
        id: 'pipedrive_cout_estime',
        label: i18n.t('pipedrive.entries.coutestime.label'),
        inputmode: 'numeric',
        mask: 'money',
        unit: '€',
      },
      messages: i18n.tm('pipedrive.entries.coutestime.messages'),
    },
  ];
  return DO_FIELDS;
};

const getHiddenField = (name, data) => ({
  name,
  value: data[name],
  field: {
    type: 'hidden',
  },
});

const getHiddenFields = (data) => {
  const type = data.pipedrive_type || DEFAULT_PIPEDRIVE_TYPE;
  return [
    getHiddenField('pipedrive_type', { pipedrive_type: type }),
    getHiddenField('pipedrive_pipeline_id', data),
    getHiddenField('pipedrive_stage_id', data),
    getHiddenField('pipedrive_user_id', data),
    getHiddenField('pipedrive_provenance', data),
    getHiddenField('pipedrive_vecteur', data),
    getHiddenField('pipedrive_typologie_produit', data),
  ];
};

const mergeFields = ({
  doFields,
  rcdRichFields,
  rcdSimpleFields,
  hiddenFields,
  isInternational,
  i18n,
}) => {
  const postalCodeField = [
    {
      name: 'postalcode',
      value: '',
      required: true,
      validator: 'zipcode',
      field: {
        id: 'postalcode',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.tm('forms.entries.zipcode.messages'),
    },
  ];

  const personFields = [
    {
      name: 'civility',
      value: '',
      required: true,
      field: {
        id: 'civility',
        label: i18n.t('forms.entries.civility.label'),
        type: 'select',
        options: [
          {
            value: '',
            label: 'Civilité',
          },
          {
            value: 'M.',
            label: 'Monsieur',
          },
          {
            value: 'Mme',
            label: 'Madame',
          },
          {
            value: 'Mme',
            label: 'Mademoiselle',
          },
        ],
      },
      messages: i18n.tm('forms.entries.civility.messages'),
    },
    {
      name: 'name',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'name',
        label: i18n.t('forms.entries.name.label'),
        maxlength: '30',
      },
      messages: i18n.tm('forms.entries.name.messages'),
    },
    {
      name: 'firstname',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'firstname',
        label: i18n.t('forms.entries.firstname.label'),
        maxlength: '20',
      },
      messages: i18n.tm('forms.entries.firstname.messages'),
    },
    ...doFields,
    ...postalCodeField,
  ];

  const bottomFields = [
    {
      name: 'email',
      value: '',
      required: true,
      validator: 'email',
      field: {
        id: 'email',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.tm('forms.entries.email.messages'),
    },
    {
      name: 'phone',
      value: '',
      required: true,
      validator: isInternational ? 'internationalPhone' : 'phone',
      field: {
        id: 'phone',
        label: i18n.t('forms.entries.phone.label'),
        type: 'tel',
      },
      messages: i18n.tm('forms.entries.phone.messages'),
    },
  ];
  const inputs = [
    ...personFields,
    ...rcdRichFields,
    ...rcdSimpleFields,
    ...bottomFields,
    ...hiddenFields,
  ];
  return inputs;
};

const generateFields = (data, i18n, formType) => {
  const type = data.pipedrive_type || DEFAULT_PIPEDRIVE_TYPE;

  const isInternational = data?.mailto?.[0]?.isInternational || false;
  const rcdRichFields =
    type === DEFAULT_PIPEDRIVE_TYPE ? getRCDRichFields(i18n, formType) : [];
  const rcdSimpleFields =
    type === 'rcd simple' ? getRCDSimpleFields(i18n, formType) : [];
  const doFields = type === 'do' ? getDOFields(i18n) : [];

  const hiddenFields = getHiddenFields(data);

  const mergedFields = mergeFields({
    doFields,
    rcdRichFields,
    rcdSimpleFields,
    hiddenFields,
    isInternational,
    i18n,
  });

  return [...mergedFields];
};

export default generateFields;
